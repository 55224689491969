import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Link,
  Divider,
  CircularProgress,
} from "@mui/material";
import "../static/styles/main.css";
import { AppContext } from "../AppContext";
import Swal from "sweetalert2";
import { useBodyBackground } from "../components/BackgroundFunction";
import config from "../config";

const Login = () => {
  const {
    setProjects,
    setSelectedProject,
    setSelectedParticipant,
    setSelectedTask,
    setSubjects,
    setSubjObjects,
    setTaskObjects,
    setTasks,
    setFiles,
    setObjects,
    setUserDetails,
    setSelectedPage,
    setProcessedFiles,
    setProcessedObjects,
  } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useBodyBackground();

  const removeSavedProperties = () => {
    setSelectedProject("");
    setProjects([]);
    setObjects([]);
    setSelectedParticipant("");
    setSubjects([]);
    setSubjObjects([]);
    setSelectedTask("");
    setTaskObjects([]);
    setTasks([]);
    setFiles([]);
    setProcessedFiles([]);
    setProcessedObjects([]);
    setSelectedPage("Projects");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    // Handle form submission logic here
    fetch(`${config.apiUrl}/logIn`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          Swal.fire({
            title: "Login failed",
            text: data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
          setLoading(false);
        } else {
          // Handle successful login
          if (data.user) {
            setUserDetails(data.user);
          }
          setLoading(false);
          removeSavedProperties();
          navigate("/", { credentials: "include" });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <Container
      maxWidth="md"
      sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className="login-container"
      >
        <Link href="https://www.emteqlabs.com/" sx={{ margin: 2 }}>
          <img
            src="https://uploads-ssl.webflow.com/621f9ebf15379e6fca810953/621f9f9815379e62c4811163_emteq-logo.png"
            style={{ width: "270px", height: "100px" }}
            alt="Emteq Labs Logo"
          />
        </Link>
        <Divider component={"div"} />
        <Typography variant="h5" component="h3" gutterBottom>
          Welcome back!
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Please enter your details to sign
          <br />
          into your account.
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <TextField
            variant="standard"
            label="Email"
            id="email"
            fullWidth
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="standard"
            label="Password"
            id="password"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {loading ? (
            <Button sx={{ marginTop: 2 }} justifyContent={"center"} disabled>
              <CircularProgress></CircularProgress>
            </Button>
          ) : (
            <Button
              sx={{ marginTop: 2 }}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              Log in
            </Button>
          )}
        </form>
        <Typography variant="body1" align="center" mt={3}>
          Don't have an account? <Link href="/signup">Sign up</Link>
        </Typography>
        <Typography variant="body1" align="center" mt={1}>
          Forgot your password? <Link href="/forgot_password">Reset it</Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
