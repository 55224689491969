import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
import { alpha } from "@mui/material/styles";
import {
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Button,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteConfirmation from "../DeleteConfirmationPrompt";
import InferencePrompt from "../InferencePrompt";
import RefreshIcon from "@mui/icons-material/Refresh";
import Swal from "sweetalert2";
import { AppContext } from "../../AppContext";
import config from "../../config";

const EnhancedTableToolbar = ({
  numSelected,
  selected,
  downloadName,
  changeFetchingFilesStatus,
}) => {
  const {
    selectedProject,
    selectedParticipant,
    selectedTask,
    selectedPage,
    taskObjects,
    files,
    setFiles,
    processed_files,
    setProcessedFiles,
    processedObjects,
    userDetails,
    processed_objects,
    setProcessedObjects,
    setTaskObjects,
    setSubjObjects,
    setObjects,
  } = useContext(AppContext);
  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);
  const [isInferencePromptOpen, setIsInferencePromptOpen] = useState(false);

  const removeFromList = (filesToDelete) => {
    if (selectedPage === "Processed files") {
      setProcessedFiles((prevFiles) =>
        prevFiles.filter((file) => !filesToDelete.includes(file.id))
      );
      setProcessedObjects((prevObjects) =>
        prevObjects.filter(
          (path) =>
            !processed_objects.some(
              (file) => file.path === path && filesToDelete.includes(file.id)
            )
        )
      );
      setProcessedObjects((prevObjects) =>
        prevObjects.filter(
          (path) =>
            !processed_objects.some(
              (file) => file.path === path && filesToDelete.includes(file.id)
            )
        )
      );
    } else {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => !filesToDelete.includes(file.id))
      );
      setTaskObjects((prevObjects) =>
        prevObjects.filter(
          (path) =>
            !files.some(
              (file) => file.path === path && filesToDelete.includes(file.id)
            )
        )
      );
      setSubjObjects((prevObjects) =>
        prevObjects.filter(
          (path) =>
            !files.some(
              (file) => file.path === path && filesToDelete.includes(file.id)
            )
        )
      );
      setObjects((prevObjects) =>
        prevObjects.filter(
          (path) =>
            !files.some(
              (file) => file.path === path && filesToDelete.includes(file.id)
            )
        )
      );
    }
  };

  const deleteFiles = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/postDeleteFiles`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: userDetails.user_id,
          selected,
          file_objects:
            selectedPage === "Processed files" ? processedObjects : taskObjects,
          selected_page: selectedPage,
        }),
      });
      const data = await response.json();

      if (data.success) {
        Swal.fire({
          title: "Success",
          text: data.success,
          icon: "success",
          showConfirmButton: true,
        });
        removeFromList(selected);
      }
      if (data.error) {
        console.error(data.error);
        Swal.fire({
          title: "Error",
          text: data.error,
          icon: "error",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "Error",
        text: "File deletion was not successful",
        icon: "error",
        showConfirmButton: true,
      });
    }
  };

  const refreshFiles = async () => {
    changeFetchingFilesStatus(true);
    try {
      const response = await fetch(`${config.apiUrl}/refreshFiles`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          selectedProject,
          selectedParticipant,
          selectedTask,
        }),
      });
      const data = await response.json();

      if (data.files) {
        setFiles(data.files);
        changeFetchingFilesStatus(false);
      }
      if (data.error) {
        console.error(data.error);
        Swal.fire({
          title: "Error",
          text: data.error,
          icon: "error",
          showConfirmButton: true,
        });
        changeFetchingFilesStatus(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        showConfirmButton: true,
      });
      changeFetchingFilesStatus(false);
    }
    changeFetchingFilesStatus(false);
  };

  const handleCloudClick = async (generateVideo) => {
    for (let i = 0; i < selected.length; i++) {
      const last_part = selected[i].split("/").pop();
      if (!last_part.endsWith(".txt")) {
        Swal.fire({
          title: "Select only .txt files!",
          text: "Selected files must be .txt for processing",
          icon: "error",
          showConfirmButton: true,
        });
        return;
      }
    }
    try {
      const response = await fetch(`${config.apiUrl}/triggerInference`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          selected,
          file_objects:
            selectedPage === "Processed files" ? processedObjects : taskObjects,
          generate_video: generateVideo,
        }),
      });
      const data = await response.json();
      if (data.error) {
        console.error(data.error);
        Swal.fire({
          title: "Error",
          text: data.error,
          icon: "error",
          showConfirmButton: true,
        });
      }
      if (data.success) {
        Swal.fire({
          title: "Selected files sent for processing",
          html: `<span style="color: red;">${data.message}</span> \
            <p> Selected files are sent to be processed in the cloud. \
            Check the Processed files tab in a few minutes for the results.</p>`,
          icon: "info",
          showConfirmButton: true, // Disables the OK button
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPresignedUrls = async (file_paths) => {
    try {
      const response = await fetch(`${config.apiUrl}/getPresignedUrls`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ file_paths, selected_page: selectedPage }),
      });
      const data = await response.json();
      if (data.error) {
        console.error(data.error);
        Swal.fire({
          title: "Error while getting urls for download",
          text: data.error,
          icon: "error",
          showConfirmButton: true,
        });
      } else {
        return data.urls;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const downloadFiles = async (selectDownload) => {
    // Show SweetAlert2 before starting the download
    const alertTimeout = setTimeout(() => {
      Swal.fire({
        title: "Downloading...",
        text: "Your files are being downloaded. Please wait.",
        icon: "info",
        showConfirmButton: true,
      });
    }, 1000); // Delay in milliseconds (adjust this as needed)

    let file_paths = selectDownload.map((file) => file.path);

    let urls = await getPresignedUrls(file_paths);
    console.log(urls);

    try {
      if (urls.length === 1) {
        // Single file download
        const response = await axios.get(urls[0], { responseType: "blob" });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        // console.log(selectDownload[0].id);
        link.setAttribute("download", selectDownload[0].file_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else if (urls.length > 1) {
        // Multiple files download as ZIP
        const zip = new JSZip();
        let downloadPromises = [];

        for (let i = 0; i < selectDownload.length; i++) {
          const response = await axios.get(urls[i], { responseType: "blob" });
          zip.file(selectDownload[i].id, response.data);
          downloadPromises.push(response);
        }

        await Promise.all(downloadPromises);

        const content = await zip.generateAsync({ type: "blob" });

        let folder_name = "";
        if (downloadName) {
          folder_name = downloadName;
        } else {
          folder_name =
            selectedProject + "_" + selectedParticipant + "_" + selectedTask;
        }
        saveAs(content, folder_name + ".zip");
      }
      Swal.close();
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      clearTimeout(alertTimeout); // Clear the timeout if download completes quickly
      // Swal.close();
    }
  };

  function get_urls(selected, files) {
    const selectDownload = [];
    selected.forEach((item) => {
      const file_ = files.find((file) => file.id === item);
      selectDownload.push(file_);
    });
    return selectDownload;
  }

  const handleDownloadClick = async () => {
    const fileDict =
      selectedPage === "Processed files" ? processed_files : files;
    const selectDownload = get_urls(selected, fileDict);
    downloadFiles(selectDownload);
  };

  const handleOpenDeletePrompt = () => {
    setIsDeletePromptOpen(true);
  };

  const handleCloseDelete = () => {
    setIsDeletePromptOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteFiles();
    setIsDeletePromptOpen(false);
  };

  const handleOpenInferencePrompt = () => {
    setIsInferencePromptOpen(true);
  };

  const handleCloseInferencePrompt = () => {
    setIsInferencePromptOpen(false);
  };

  const handleConfirmInference = () => {
    handleCloudClick("True");
    setIsInferencePromptOpen(false);
  };

  const handleNoVideoInference = () => {
    handleCloudClick("False");
    setIsInferencePromptOpen(false);
  };

  const handleRefreshClick = () => {
    refreshFiles();
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Files
          {selectedPage == "Projects" && (
            <Button
              variant="text"
              onClick={handleRefreshClick}
              style={{
                backgroundColor: "transparent !important", // Transparent background
                padding: 0, // Optional: Adjust padding if necessary
              }}
              className="no-bg-button"
              title="Refresh table"
            >
              <RefreshIcon />
            </Button>
          )}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Grid sx={{ display: "flex", flexDirection: "row" }}>
          {selectedPage === "Projects" && (
            <Tooltip title="Process with Cloud AI" sx={{ margin: 2 }}>
              <IconButton onClick={handleOpenInferencePrompt}>
                <CloudSyncIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Download" sx={{ margin: 2 }}>
            <IconButton onClick={handleDownloadClick}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" sx={{ margin: 2 }}>
            <IconButton onClick={handleOpenDeletePrompt}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <Grid
          sx={{ display: "flex", flexDirection: "row" }}
          title="Select files to enable buttons"
        >
          {selectedPage === "Projects" && (
            <Grid item sx={{ margin: 2 }}>
              <IconButton sx={{ color: "white" }} disabled>
                <CloudSyncIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item sx={{ margin: 2 }}>
            <IconButton disabled>
              <CloudDownloadIcon />
            </IconButton>
          </Grid>
          <Grid item sx={{ margin: 2 }}>
            <IconButton disabled>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <DeleteConfirmation
        message={"these files"}
        open={isDeletePromptOpen}
        handleClose={handleCloseDelete}
        handleConfirm={handleConfirmDelete}
      />
      <InferencePrompt
        open={isInferencePromptOpen}
        handleClose={handleCloseInferencePrompt}
        handleDeny={handleNoVideoInference}
        handleConfirm={handleConfirmInference}
      ></InferencePrompt>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
};

export default EnhancedTableToolbar;
