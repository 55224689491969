import React, { useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  CardContent,
  Avatar,
  Divider,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsMenu from "../components/SettingsMenu";
import { AppContext } from "../AppContext";

const Profile = () => {
  const { userDetails } = useContext(AppContext);

  return (
    <Container>
      <Box sx={{ padding: 3 }}>
        <Card sx={{ marginTop: 3, padding: 3, borderRadius: 2 }}>
          <SettingsMenu />
          <Box sx={{ padding: 3, borderRadius: 2, textAlign: "center" }}>
            <Avatar sx={{ width: 120, height: 120, margin: "0 auto" }}>
              <AccountCircleIcon sx={{ fontSize: 100 }} />
            </Avatar>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", marginTop: 2, color: "white" }}
            >
              {userDetails.user_name}
            </Typography>
            <Typography variant="h6" sx={{ color: "white" }}>
              {userDetails.email}
            </Typography>
          </Box>
          <Divider variant="middle" component={Box} />
          <CardContent sx={{ marginTop: 5 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
            >
              Profile Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Your name:
                </Typography>
                <Typography variant="body1">{userDetails.user_name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Your e-mail:
                </Typography>
                <Typography variant="body1">{userDetails.email}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 3 }} />
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Profile;
