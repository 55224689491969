import React, { useContext } from "react";
import { AppContext } from "../AppContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Swal from "sweetalert2";
import config from "../config";

export default function FormDialog(props) {
  const file_id = props.file_id;
  const { files, setFiles } = useContext(AppContext);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeFileDescription = (file_id, newDescription) => {
    setFiles((prevData) =>
      prevData.map((file) =>
        file.id === file_id ? { ...file, description: newDescription } : file
      )
    );
  };

  const fetchFiles = async (file_id, description) => {
    await fetch(`${config.apiUrl}/postDescription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ file_id, description }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error(data.error);
          Swal.fire({
            title: "Description update failed",
            text: data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
        if (data.success) {
          // Handle successful submiting
          Swal.fire({
            title: "Successfully updated description",
            text: "View the new description in the table",
            icon: "success",
            confirmButtonText: "OK",
          });
          changeFileDescription(file_id, description);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <React.Fragment>
      <Link onClick={handleClickOpen}>Click to update description</Link>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const description = formJson.description;
            console.log(file_id, description);
            fetchFiles(file_id, description);
            handleClose();
          },
        }}
      >
        <DialogTitle sx={{ marginBottom: 1, fontWeight: "bold" }}>
          Add description
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the description you want to add to the file.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "white" }}>
            Cancel
          </Button>
          <Button type="submit" style={{ color: "green" }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
