export default function ErrorPage() {
  return (
    <div
      className={"error-page"}
      style={{ margin: "20%", textAlign: "center" }}
    >
      <h1 className={"oops"}>Oops!</h1> <br />
      <h3 className={"message"}>Something went wrong...</h3>
    </div>
  );
}
