import React, { useState, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteConfirmation from "./DeleteConfirmationPrompt";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../config";

const SettingsMenu = () => {
  const {
    setProjects,
    setSelectedProject,
    setSelectedParticipant,
    setSelectedTask,
    setSubjects,
    setSubjObjects,
    setTaskObjects,
    setTasks,
    setFiles,
    setObjects,
    setUserDetails,
    setProcessedFiles,
    setProcessedObjects,
    setSelectedPage,
    userDetails,
  } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePasswordClick = () => {
    navigate("/change_password");
    handleClose();
  };

  const removeSavedProperties = () => {
    setSelectedProject("");
    setProjects([]);
    setObjects([]);
    setSelectedParticipant("");
    setSubjects([]);
    setSubjObjects([]);
    setSelectedTask("");
    setTaskObjects([]);
    setTasks([]);
    setFiles([]);
    setUserDetails({});
    setProcessedFiles([]);
    setProcessedObjects([]);
    setSelectedPage("Projects");
  };

  const handleLogoutCLick = () => {
    fetch(`${config.apiUrl}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ access_token: userDetails.access_token }),
    }).then((response) => {
      if (response.status === 200) {
        navigate("/login");
      }
    });
    handleClose();
    removeSavedProperties();
  };

  const handleDeleteAccountClick = () => {
    setIsDeletePromptOpen(true);
    handleClose();
  };

  const handleConfirmDelete = () => {
    deleteAccount();
    setIsDeletePromptOpen(false);
  };

  const handleCloseDelete = () => {
    setIsDeletePromptOpen(false);
  };

  const deleteAccount = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/delete`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      if (data.error) {
        console.error(data.error);
        Swal.fire({
          title: "An Error occured",
          text: data.error,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
      if (data.success) {
        Swal.fire({
          title: "Success",
          text: data.success,
          icon: "success",
        });
        navigate("/login");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2, float: "right" }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <SettingsIcon sx={{ width: 32, height: 32 }}></SettingsIcon>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleChangePasswordClick}>
          <ListItemIcon>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          Change password
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogoutCLick}>
          <ListItemIcon>
            <Logout fontSize="small" sx={{ color: "blue" }} />
          </ListItemIcon>
          Logout
        </MenuItem>
        <MenuItem onClick={handleDeleteAccountClick}>
          <ListItemIcon>
            <HighlightOffIcon fontSize="small" sx={{ color: "red" }} />
          </ListItemIcon>
          Delete account
        </MenuItem>
      </Menu>
      <DeleteConfirmation
        message={"your account"}
        open={isDeletePromptOpen}
        handleClose={handleCloseDelete}
        handleConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

export default SettingsMenu;
