// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    align-content: center;
    align-items: center;
    font-family: sans-serif;

}

.white-outline .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
}

.white-outline .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: white;
}

.white-outline .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: white;
}

.white-outline .MuiInputLabel-root {
    color: white;
}

.white-outline .MuiSelect-icon {
    color: white;
}

.white-outline .Mui-selected {
    color: white !important;
}

.white-outline .MuiMenuItem-root {
    color: white;
}

.strong-border .MuiOutlinedInput-root  {
      border-radius: 2px,
    }
    
.strong-border  .MuiOutlinedInput-root:hover{
     border-radius: 2px, 
    }

.strong-border  .Mui-focused {
    border-radius: 2px
}`, "",{"version":3,"sources":["webpack://./src/static/styles/projects.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,uBAAuB;;AAE3B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;MACM;IACF;;AAEJ;KACK;IACD;;AAEJ;IACI;AACJ","sourcesContent":["body {\n    align-content: center;\n    align-items: center;\n    font-family: sans-serif;\n\n}\n\n.white-outline .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {\n    border-color: white;\n}\n\n.white-outline .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {\n    border-color: white;\n}\n\n.white-outline .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {\n    border-color: white;\n}\n\n.white-outline .MuiInputLabel-root {\n    color: white;\n}\n\n.white-outline .MuiSelect-icon {\n    color: white;\n}\n\n.white-outline .Mui-selected {\n    color: white !important;\n}\n\n.white-outline .MuiMenuItem-root {\n    color: white;\n}\n\n.strong-border .MuiOutlinedInput-root  {\n      border-radius: 2px,\n    }\n    \n.strong-border  .MuiOutlinedInput-root:hover{\n     border-radius: 2px, \n    }\n\n.strong-border  .Mui-focused {\n    border-radius: 2px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
