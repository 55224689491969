import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "./AppContext";

const PrivateRoute = ({ children }) => {
  const { userDetails } = useContext(AppContext);

  return userDetails.user_id ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
