import React, { createContext } from 'react';
import useSessionStorage from './components/SessionStorage';

export const AppContext = createContext();

// Context provider component
export const AppProvider = ({ children }) => {
  const [projects, setProjects] = useSessionStorage('projects', []);
  const [objects, setObjects] = useSessionStorage('objects', []);
  const [userDetails, setUserDetails] = useSessionStorage('userDetails', {});
  const [selectedProject, setSelectedProject] =  useSessionStorage('selectedProject', '');
  const [selectedParticipant, setSelectedParticipant] =  useSessionStorage('selectedParticipant', '');
  const [selectedTask, setSelectedTask] =  useSessionStorage('selectedTask', '');
  const [subjects, setSubjects] = useSessionStorage('subjects', []);
  const [subjObjects, setSubjObjects] = useSessionStorage('subjObjects', []);
  const [taskObjects, setTaskObjects] = useSessionStorage('taskObjects', []);
  const [tasks, setTasks] = useSessionStorage('tasks', []);
  const [files, setFiles] = useSessionStorage('files', []);
  const [processed_files, setProcessedFiles] = useSessionStorage('processed_files', []);
  const [processedObjects, setProcessedObjects] = useSessionStorage('processedObjects', []);
  const [selectedPage, setSelectedPage] = useSessionStorage('selectedPage', 'Projects');

  return (
    <AppContext.Provider value={{ projects, setProjects, 
                                  selectedProject, setSelectedProject, 
                                  selectedParticipant, setSelectedParticipant, 
                                  selectedTask, setSelectedTask, 
                                  subjects, setSubjects,
                                  subjObjects, setSubjObjects,
                                  taskObjects, setTaskObjects,
                                  tasks, setTasks,
                                  files, setFiles,
                                  objects, setObjects, 
                                  userDetails, setUserDetails,
                                  selectedPage, setSelectedPage,
                                  processed_files, setProcessedFiles,
                                  processedObjects, setProcessedObjects
                                }}>
      {children}
    </AppContext.Provider>
  );
};
