// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
display: flex;
flex-direction: column;
}

input[type="email"]:focus,
input[type="text"]:focus,
input[type="password"]:focus { 
  outline: none;
  border: 2px solid #7f99fa;
}

.submit-button {
  margin-top: 20px;
  padding: 10px;
  width: 250px;
  margin-left: 20%;
}

a {
color: #7f99fa;
text-decoration: none;
text-align: center;
align-items: center;
justify-content: center;
}

a:hover {
text-decoration: underline;
}

.login-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.input-field {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}



  `, "",{"version":3,"sources":["webpack://./src/static/styles/signup.css"],"names":[],"mappings":"AAAA;AACA,aAAa;AACb,sBAAsB;AACtB;;AAEA;;;EAGE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB;;AAEA;AACA,cAAc;AACd,qBAAqB;AACrB,kBAAkB;AAClB,mBAAmB;AACnB,uBAAuB;AACvB;;AAEA;AACA,0BAA0B;AAC1B;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,uCAAuC;IACvC,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;EAClC;;AAEF;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,wCAAwC;AAC1C","sourcesContent":["form {\ndisplay: flex;\nflex-direction: column;\n}\n\ninput[type=\"email\"]:focus,\ninput[type=\"text\"]:focus,\ninput[type=\"password\"]:focus { \n  outline: none;\n  border: 2px solid #7f99fa;\n}\n\n.submit-button {\n  margin-top: 20px;\n  padding: 10px;\n  width: 250px;\n  margin-left: 20%;\n}\n\na {\ncolor: #7f99fa;\ntext-decoration: none;\ntext-align: center;\nalign-items: center;\njustify-content: center;\n}\n\na:hover {\ntext-decoration: underline;\n}\n\n.login-container {\n    max-width: 500px;\n    margin: 0 auto;\n    padding: 30px;\n    background-color: #fff;\n    border-radius: 5px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n  \n.centered-image {\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.input-field {\n  padding: 10px;\n  margin-bottom: 20px;\n  border-radius: 5px;\n  border: none;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);\n}\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
