import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import ProjectsTable from "../components/table/ProjectsTable";
import { AppContext } from "../AppContext";
import "../static/styles/projects.css";
import DownloadArchive from "../components/DownloadArchive";
import config from "../config";

const Projects = () => {
  const {
    projects,
    setProjects,
    selectedProject,
    setSelectedProject,
    selectedParticipant,
    setSelectedParticipant,
    selectedTask,
    setSelectedTask,
    subjects,
    setSubjects,
    subjObjects,
    setSubjObjects,
    taskObjects,
    setTaskObjects,
    tasks,
    setTasks,
    files,
    setFiles,
    objects,
    setObjects,
    userDetails,
  } = useContext(AppContext);
  const [fetchingProjects, setFetchingProjects] = useState(false);
  const [fetchingParticipants, setFetchingParticipants] = useState(false);
  const [fetchingTasks, setFetchingTasks] = useState(false);
  const [fetchingFiles, setFetchingFiles] = useState(false);

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        `${config.apiUrl}/getProjects?user_id=${userDetails.user_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.error) {
        console.error(data.error);
      } else {
        if (projects !== data.projects) {
          setProjects(data.projects);
        }
        if (objects !== data.objects) {
          setObjects(data.objects);
        }
        setFetchingProjects(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setFetchingProjects(false);
    }
  };

  const fetchSubjects = async (project) => {
    await fetch(`${config.apiUrl}/getParticipants?project_id=${project}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ objects }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error(data.error);
        }
        setSubjects(data.subjects);
        setSubjObjects(data.objects);
        setFetchingParticipants(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setFetchingParticipants(false);
      });
  };

  const fetchTasks = async (subject) => {
    await fetch(
      `${config.apiUrl}/getTasks?project_id=${selectedProject}&subject_id=${subject}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ objects: subjObjects }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error(data.error);
        }
        setTasks(data.tasks);
        setTaskObjects(data.objects);
        setFetchingTasks(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setFetchingTasks(false);
      });
  };

  const fetchFiles = async (task) => {
    await fetch(
      `${config.apiUrl}/getFiles?project_id=${selectedProject}&subject_id=${selectedParticipant}&task_id=${task}&user_id=${userDetails.user_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ objects: taskObjects }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error(data.error);
        }
        setFiles(data.files);
        setFetchingFiles(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setFetchingFiles(false);
      });
  };

  useEffect(() => {
    setFetchingProjects(true);
    fetchProjects();
  }, [userDetails]);

  const handleProjectChange = (event) => {
    let project = event.target.value;
    setSelectedProject(project);
    setSelectedParticipant("");
    setSubjects([]);
    setSelectedTask("");
    setTasks([]);
    setFiles([]);
    setFetchingParticipants(true);

    fetchSubjects(project);
  };

  const handleParticipantChange = (event) => {
    let subject = event.target.value;
    setSelectedParticipant(subject);
    setSelectedTask("");
    setTasks([]);
    setFiles([]);
    setFetchingTasks(true);

    fetchTasks(subject);
  };

  const handleTaskChange = (event) => {
    let task = event.target.value;
    setSelectedTask(task);
    setFiles([]);
    setFetchingFiles(true);

    fetchFiles(task);
  };

  const changeFetchingFilesStatus = (status) => {
    setFetchingFiles(status);
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <Box
          sx={{
            marginTop: 5,
            overflow: "hidden",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Grid
            container
            paddingY={2}
            // spacing={4}
            sx={{
              position: "relative",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              boxSizing: "border-box",
            }}
          >
            <Grid
              item
              xs={3}
              md={3}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ backgroundColor: "white", borderRadius: 2 }}
              >
                <InputLabel id="project-label">Select Project</InputLabel>
                <Select
                  labelId="project-label"
                  id="project-select"
                  value={selectedProject}
                  label="Select Project"
                  onChange={handleProjectChange}
                  endAdornment={
                    fetchingProjects && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "16px",
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    )
                  }
                >
                  {selectedProject && (
                    <MenuItem value="" sx={{ fontVariant: "petite-caps" }}>
                      <em>remove selection</em>
                    </MenuItem>
                  )}
                  {!fetchingProjects && projects.length === 0 ? (
                    <MenuItem disabled>You have no projects</MenuItem>
                  ) : (
                    projects.map((project) => (
                      <MenuItem key={project} value={project}>
                        {project}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              {selectedProject && (
                <DownloadArchive
                  filePaths={subjObjects}
                  message={"project"}
                ></DownloadArchive>
              )}
            </Grid>
            <Grid
              item
              xs={3}
              md={3}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <FormControl
                fullWidth
                variant="outlined"
                className="strong-border"
                sx={{ backgroundColor: "white", borderRadius: 2 }}
              >
                <InputLabel id="subject-label">Select Participant</InputLabel>
                <Select
                  labelId="subject-label"
                  id="subject-select"
                  value={selectedParticipant}
                  label="Select Participant"
                  onChange={handleParticipantChange}
                  endAdornment={
                    fetchingParticipants && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "16px",
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    )
                  }
                >
                  {selectedParticipant && (
                    <MenuItem value="" sx={{ fontVariant: "petite-caps" }}>
                      <em>remove selection</em>
                    </MenuItem>
                  )}
                  {!fetchingParticipants && subjects.length === 0 ? (
                    <MenuItem disabled>
                      There are no participants for this project
                    </MenuItem>
                  ) : (
                    subjects.map((subject) => (
                      <MenuItem key={subject} value={subject}>
                        {subject}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              {selectedParticipant && (
                <DownloadArchive
                  filePaths={taskObjects}
                  message={"participant"}
                ></DownloadArchive>
              )}
            </Grid>
            <Grid item xs={3} md={3}>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ backgroundColor: "white", borderRadius: 2 }}
              >
                <InputLabel id="task-label">Select Task</InputLabel>
                <Select
                  labelId="task-label"
                  id="task-select"
                  value={selectedTask}
                  label="Select Task"
                  onChange={handleTaskChange}
                  endAdornment={
                    fetchingTasks && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "16px",
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    )
                  }
                >
                  {selectedTask && (
                    <MenuItem value="" sx={{ fontVariant: "petite-caps" }}>
                      <em>remove selection</em>
                    </MenuItem>
                  )}
                  {!fetchingTasks && tasks.length === 0 ? (
                    <MenuItem disabled>
                      There are no tasks for this participant
                    </MenuItem>
                  ) : (
                    tasks.map((task) => (
                      <MenuItem key={task} value={task}>
                        {task}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <ProjectsTable
            files={files}
            fetchingFiles={fetchingFiles}
            changeFetchingFilesStatus={changeFetchingFilesStatus}
          />
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Projects;
