import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, TextField, Typography, Link } from "@mui/material";
import config from "../config";

const ConfirmPasswordChange = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verification, setVerification] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorResent, setErrorResent] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add form submission logic here
    fetch(`${config.apiUrl}/confirmForgotPassword?email=${email}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        verification_code: verification,
        new_password: password,
        confirm_password: confirmPassword,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          // Handle successful response
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleResend = () => {
    if (email === "") {
      setErrorResent("Please enter your email!");
    } else {
      fetch(`/resendVerificationCode?email=${email}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setErrorMessage(data.error);
          } else {
            // Handle successful response
            navigate("/");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      sx={{ mt: 5 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className="login-container"
      >
        <Link href="https://www.emteqlabs.com/" underline="none">
          <Box
            component="img"
            src="https://uploads-ssl.webflow.com/621f9ebf15379e6fca810953/621f9f9815379e62c4811163_emteq-logo.png"
            alt="Emteq Labs Logo"
            sx={{
              width: "270px",
              height: "100px",
              marginBottom: 3,
            }}
          />
        </Link>

        <Typography variant="h5" gutterBottom>
          Confirm the password change
        </Typography>
        <Typography variant="body1" gutterBottom>
          Confirm your password change to use our OCOsense applications.
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ width: "100%", maxWidth: 400, mt: 2 }}
        >
          <TextField
            fullWidth
            margin="normal"
            variant="standard"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            variant="standard"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            variant="standard"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <TextField
            fullWidth
            variant="standard"
            margin="normal"
            label="Verification Code"
            type="text"
            value={verification}
            onChange={(e) => setVerification(e.target.value)}
            required
          />

          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{
              mt: 2,
              background:
                "linear-gradient(to right bottom, #ce80f8, #a68ffe, #7f99fa, #609fef, #50a2df)",
              "&:hover": {
                opacity: 0.8,
              },
            }}
          >
            Confirm Account
          </Button>
        </Box>

        <Typography variant="body2" sx={{ mt: 3 }}>
          Resend the{" "}
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleResend();
            }}
            sx={{ color: "#7f99fa", textDecoration: "none" }}
          >
            verification code
          </Link>{" "}
          again
        </Typography>

        <Typography variant="body2" sx={{ mt: 1 }}>
          <Link href="/" sx={{ color: "#7f99fa", textDecoration: "none" }}>
            Back to Log In
          </Link>
        </Typography>

        {errorMessage && (
          <Typography color="error" sx={{ mt: 2, fontWeight: "bold" }}>
            {errorMessage}
          </Typography>
        )}
        {errorResent && (
          <Typography color="error" sx={{ mt: 2, fontWeight: "bold" }}>
            {errorResent}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default ConfirmPasswordChange;
