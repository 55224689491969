import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Link,
} from "@mui/material";
import { useBodyBackground } from "../components/BackgroundFunction";
import config from "../config";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  useBodyBackground();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    fetch(`${config.apiUrl}/forgotPassword?email=${email}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.error);
        }
        if (data.success) {
          // Handle successful response
          navigate("/confirm_forgot_password");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Container maxWidth="xs" className="container">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className="login-container"
      >
        <Box textAlign="center" mb={3}>
          <a href="https://www.emteqlabs.com/">
            <img
              src="https://uploads-ssl.webflow.com/621f9ebf15379e6fca810953/621f9f9815379e62c4811163_emteq-logo.png"
              alt="Emteq Labs Logo"
              style={{ width: "270px", height: "100px" }}
            />
          </a>
        </Box>

        <Typography variant="h5" align="center" gutterBottom>
          Get code to reset password
        </Typography>

        <Typography align="center" gutterBottom>
          Please enter your email to get the code for reseting password.
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ width: "100%", maxWidth: 400, mt: 2 }}
        >
          <TextField
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            margin="normal"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 2 }}
          >
            Get reset code
          </Button>
        </Box>

        <Typography align="center" sx={{ mt: 2 }}>
          <Link href="/" underline="hover" color="primary">
            Back to Log In
          </Link>
        </Typography>

        {errorMessage && (
          <Typography
            color="error"
            align="center"
            sx={{ mt: 2, fontWeight: "bold" }}
          >
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default ForgotPassword;
