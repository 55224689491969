
import { useEffect } from 'react';

export function useBodyBackground(color) {
    useEffect(() => {
      // Set body background color when component is mounted
      if (color){
        document.body.style.background = color;
      }
      else {
        document.body.style.background = 'linear-gradient(to right bottom, \
        #ce80f8,#a68ffe 25%, #7f99fa 50%, #609fef 75%, #50a2df)'
      }
    }, [color]);
  }