import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Link,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import "../static/styles/signup.css";
import Swal from "sweetalert2";
import { useBodyBackground } from "../components/BackgroundFunction";
import config from "../config";

const Signup = () => {
  const [termsChecked, setTermsChecked] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const navigate = useNavigate();
  useBodyBackground();

  const handleTermsChange = (event) => {
    setTermsChecked(event.target.checked);
  };

  const regex =
    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

  const handleSignUpSubmit = (event) => {
    event.preventDefault();

    if (!firstName || !lastName || !email || !password || !passwordConfirm) {
      Swal.fire({
        title: "Missing fields",
        text: "Please fill in all the required fields.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    } else if (password !== passwordConfirm) {
      Swal.fire({
        title: "Password mismatch",
        text: "Please ensure that the password and confirm password are the same.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    } else if (!regex.test(password)) {
      console.log(regex.test(password));
      console.log(password);
      Swal.fire({
        title: "Invalid password",
        text: 'Password must contain at least 8 characters, one uppercase letter, one special character and one number!"',
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    } else {
      fetch(`${config.apiUrl}/signUp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          passwordConfirm,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            Swal.fire({
              title: "Signup failed",
              text: data.error,
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "Signup successful",
              text: "Check your email to verify your account.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              navigate(`/verify`);
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: "An error occurred. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className="login-container"
      >
        <Link href="https://www.emteqlabs.com/" sx={{ margin: 2 }}>
          <img
            src="https://uploads-ssl.webflow.com/621f9ebf15379e6fca810953/621f9f9815379e62c4811163_emteq-logo.png"
            style={{ width: "270px", height: "100px" }}
            alt="Emteq Labs Logo"
          />
        </Link>
        <Typography variant="h5" component="h3" gutterBottom>
          Create an account
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Create an account to use our OCOsense applications.
        </Typography>
        <form onSubmit={handleSignUpSubmit} style={{ width: "100%" }}>
          <TextField
            variant="standard"
            label="First Name"
            id="firstName"
            fullWidth
            margin="normal"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            variant="standard"
            label="Last Name"
            id="lastName"
            fullWidth
            margin="normal"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            variant="standard"
            label="Email"
            id="email"
            fullWidth
            margin="normal"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="standard"
            label="Password"
            id="password"
            fullWidth
            margin="normal"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant="standard"
            label="Confirm Password"
            id="confirmpassword"
            fullWidth
            margin="normal"
            type="password"
            required
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={termsChecked}
                onChange={handleTermsChange}
                name="terms"
                color="primary"
                required
              />
            }
            label={
              <Typography variant="body1" display={"inline-block"}>
                I accept the{" "}
                <Link
                  href="https://www.emteqlabs.com/terms-conditions"
                  color="primary"
                  underline="none"
                >
                  Terms and Conditions
                </Link>
              </Typography>
            }
          />
          <Button
            sx={{ marginTop: 2 }}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!termsChecked}
          >
            Sign Up
          </Button>
        </form>
        <Typography variant="body1" align="center" mt={3}>
          You already have an account?{" "}
          <Link href="/" color="primary" underline="none">
            Log In
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Signup;
