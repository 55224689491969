import React, { useContext } from "react";
import Projects from "./Projects";
import Profile from "./Profile";
import ProcessedDataTable from "./ProcessedFiles";
import { AppContext } from "../AppContext";
import { Box, Typography, Link, Grid } from "@mui/material";
import { useBodyBackground } from "../components/BackgroundFunction";

const NavigationBar = () => {
  const { selectedPage, setSelectedPage } = useContext(AppContext);

  const handleSelection = (item) => {
    setSelectedPage(item);
  };

  useBodyBackground("rgb(230, 227, 232)");

  return (
    <React.Fragment>
      <Box
        sx={{ width: "100%", boxSizing: "border-box" }}
        className="linear-bg"
      >
        <Grid
          container
          sx={{
            display: "flex",
            position: "relative",
            alignItems: "center",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          <Grid item xs={3} md={2} sx={{ alignContent: "left", flexShrink: 1 }}>
            <Link href="https://www.emteqlabs.com/" sx={{ margin: 2 }}>
              <img
                src="https://uploads-ssl.webflow.com/621f9ebf15379e6fca810953/621f9f9815379e62c4811163_emteq-logo.png"
                style={{ width: "100%", maxWidth: "216px", height: "auto" }}
                alt="Emteq Labs Logo"
              />
            </Link>
          </Grid>

          <Grid
            item
            xs={6}
            md={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {["Projects", "Processed files", "Profile"].map((item) => (
              <Typography
                key={item}
                sx={{
                  minWidth: 100,
                  marginX: { xs: 0, sm: 2 },
                  cursor: "pointer",
                  position: "relative",
                  borderBottom:
                    selectedPage === item ? "2px solid white" : "none",
                  paddingBottom: 1,
                  color: "white",
                  fontSize: "large",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    height: "2px",
                    width: selectedPage ? "100%" : "0",
                    backgroundColor: "white",
                    transition: "width 0.3s ease-in-out",
                  },
                }}
                onClick={() => handleSelection(item)}
              >
                {item}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 5,
          backgroundColor: "rgb(230, 227, 232)",
          width: "100%",
          height: "100vh",
          boxSizing: "border-box",
        }}
      >
        {selectedPage === "Projects" && <Projects />}
        {selectedPage === "Profile" && <Profile />}
        {selectedPage === "Processed files" && <ProcessedDataTable />}
      </Box>
    </React.Fragment>
  );
};

export default NavigationBar;
