import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppProvider } from "./AppContext";
import Login from "./pages/Login";
import NavigationBar from "./pages/NavigationPage";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ConfirmForgotPassword from "./pages/ConfirmForgotPassword";
import Verification from "./pages/Verification";
import PasswordReset from "./pages/ChangePassword";
import PrivateRoute from "./PrivateRoute";
import "./App.css";

function App() {
  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verify" element={<Verification />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route
            path="/confirm_forgot_password"
            element={<ConfirmForgotPassword />}
          />
          <Route
            path="/change_password"
            element={
              <PrivateRoute>
                <PasswordReset />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <NavigationBar />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AppProvider>
  );
}

export default App;
