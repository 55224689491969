const configs = {
  staging: {
    apiUrl: "https://1pp6kom8f8.execute-api.eu-west-1.amazonaws.com/staging",
  },
  prod: {
    apiUrl: "https://f4y9xtl0oh.execute-api.eu-west-1.amazonaws.com/prod",
  },
};

const environment = process.env.REACT_APP_STAGE || "staging";
const config = configs[environment];

export default config;
