import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import EnhancedTableToolbar from "../components/table/EnhancedTableToolbar";
import { Container } from "@mui/material";
import { AppContext } from "../AppContext";
import { DataGrid } from "@mui/x-data-grid";
import config from "../config";

const ProcessedDataTable = () => {
  const {
    processed_files,
    setProcessedFiles,
    setProcessedObjects,
    userDetails,
  } = useContext(AppContext);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { field: "project", headerName: "Project", width: 180 },
    { field: "participant", headerName: "Participant", width: 150 },
    { field: "task", headerName: "Task", width: 150 },
    { field: "file_name", headerName: "File name", width: 250 },
    { field: "date", headerName: "Date", width: 150 },
    {
      field: "size",
      headerName: "Size",
      width: 150,
      valueFormatter: (size) => {
        return `${size} MB`;
      },
    },
  ];

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(
          `${config.apiUrl}/getProcessedFiles?user_id=${userDetails.user_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (data.files) {
          setProcessedFiles(data.files);
          setProcessedObjects(data.objects);
          setFetchingFiles(false);
        }
        if (data.error) {
          console.error(data.error);
          navigate("/login");
          setFetchingFiles(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setFetchingFiles(false);
      }
    };
    setFetchingFiles(true);
    fetchFiles();
  }, [navigate]);

  return (
    <React.Fragment>
      <Container sx={{ marginTop: 5 }}>
        <Paper style={{ width: "100%" }}>
          <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
            <EnhancedTableToolbar
              numSelected={selectedRows.length}
              selected={selectedRows}
              downloadName={"processed_files"}
            />
          </div>
          <DataGrid
            rows={processed_files}
            columns={columns}
            loading={fetchingFiles}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              console.log(newRowSelectionModel);
              setSelectedRows(newRowSelectionModel);
            }}
            rowSelectionModel={selectedRows}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            sx={{
              borderRadius: "8px", // Rounded corners for the table
              padding: 2, // Padding around the table
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#e3f2fd", // Header background color
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bolder", // Make header text bold
                fontSize: "16px", // Header text size
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)", // Cell bottom border
              },
              "& .MuiDataGrid-row": {
                backgroundColor: "#f5f5f5",
                "&:hover": {
                  backgroundColor: "#e0f7fa", // Hover effect for rows
                },
              },
            }}
          />
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default ProcessedDataTable;
