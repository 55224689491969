import React, { useState, useContext } from "react";
import { Container, TextField, Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AppContext } from "../AppContext";
import { useBodyBackground } from "../components/BackgroundFunction";
import config from "../config";

const PasswordReset = () => {
  const { userDetails } = useContext(AppContext);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  useBodyBackground();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      Swal.fire({
        title: "Password mismatch",
        text: "Please ensure that the new password and confirm password are the same.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    const regex =
      /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
    if (!regex.test(newPassword)) {
      console.log(newPassword);
      Swal.fire({
        title: "Invalid password",
        text: "Please ensure that the new password has at least 1 uppercase letter, at least 1 digit, at least 1 special character like !,@,#,$,%,^,&,* and is at least 8 characters long.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    if (oldPassword === newPassword) {
      Swal.fire({
        title: "Invalid password",
        text: "The old password and the new password cannot be the same.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    try {
      fetch(`${config.apiUrl}/changePassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
          access_token: userDetails.access_token,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            Swal.fire({
              title: "Password reset successful",
              text: "Your password has been reset successfully.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              navigate("/");
            });
          }
          if (data.error) {
            Swal.fire({
              title: "Password reset failed",
              text: data.error,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        textAlign="center"
        className="login-container"
      >
        <Box alignItems={"center"}>
          <img
            src="https://uploads-ssl.webflow.com/621f9ebf15379e6fca810953/621f9f9815379e62c4811163_emteq-logo.png"
            alt="Logo"
            style={{ width: 270, height: 100 }}
          />
        </Box>
        <Typography variant="h4" gutterBottom>
          Password Reset
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please enter your old password and new password to reset your
          password.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Old Password"
            type="password"
            fullWidth
            margin="normal"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
            variant="standard"
          />
          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            variant="standard"
          />
          <TextField
            label="Confirm New Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            variant="standard"
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => (window.location.href = "/")}
            >
              Back
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Reset Password
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default PasswordReset;
