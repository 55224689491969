import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { TextField, Button, Typography } from "@mui/material";
import { useBodyBackground } from "../components/BackgroundFunction";
import config from "../config";

const Verification = () => {
  const [email, setEmail] = useState("");
  const [verification, setVerification] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorResent, setErrorResent] = useState("");
  const navigate = useNavigate();
  useBodyBackground();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add form submission logic here
    fetch(`${config.apiUrl}/verifySignUp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, verification_code: verification }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.error);
        }
        if (data.success) {
          // Handle successful signup
          Swal.fire({
            title: "Successful verification",
            text: "An account has been created successfully.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/");
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleResend = () => {
    if (email === "") {
      setErrorResent("Please enter your email!");
    } else {
      fetch(`${config.apiUrl}/resendVerificationCode?email=${email}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setErrorMessage(data.error);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <div
      className="login-container"
      style={{ padding: "20px", textAlign: "center" }}
    >
      <a href="https://www.emteqlabs.com/">
        <img
          src="https://uploads-ssl.webflow.com/621f9ebf15379e6fca810953/621f9f9815379e62c4811163_emteq-logo.png"
          alt="Emteq Labs Logo"
          style={{ width: "270px", height: "100px" }}
        />
      </a>
      <Typography variant="h5" component="h3" gutterBottom mt={2}>
        Verify account
      </Typography>
      <p>Confirm your account to use our OCOsense applications.</p>
      <form onSubmit={handleSubmit}>
        <TextField
          type="email"
          label="Email"
          variant="standard"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          type="text"
          label="Verification code"
          variant="standard"
          fullWidth
          margin="normal"
          value={verification}
          onChange={(e) => setVerification(e.target.value)}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
          fullWidth
        >
          Confirm Account
        </Button>
      </form>
      <p style={{ marginTop: "20px" }}>
        Resend the{" "}
        <button
          style={{
            color: "#7f99fa",
            textDecoration: "underline",
            background: "none",
            padding: 0,
          }}
          onClick={(e) => {
            e.preventDefault();
            handleResend();
          }}
        >
          verification code
        </button>{" "}
        again
      </p>
      <p style={{ marginTop: "20px" }}>
        You already have an account?{" "}
        <a style={{ color: "#7f99fa", textDecoration: "none" }} href="/">
          Log In
        </a>
      </p>
      {errorMessage && (
        <p style={{ color: "red", fontWeight: "bold" }}>{errorMessage}</p>
      )}
      {errorResent && (
        <p style={{ color: "red", fontWeight: "bold" }}>{errorResent}</p>
      )}
    </div>
  );
};

export default Verification;
