import * as React from 'react';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { Box, TableHead, TableRow, TableCell, TableSortLabel, Checkbox } from '@mui/material';


const headCells = [
{
    id: 'fileName',
    numeric: false,
    disablePadding: true,
    label: 'File name',
    sort: false
},
{
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Date',
    sort: true
},
{
    id: 'duration',
    numeric: true,
    disablePadding: false,
    label: 'Duration',
    sort: true
},
{
    id: 'size',
    numeric: true,
    disablePadding: false,
    label: 'Size',
    sort: true
},
{
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    sort: false
},
];

function EnhancedTableHead(props) {
const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
};

return (
    <TableHead>
    <TableRow>
        <TableCell padding="checkbox">
        <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
            'aria-label': 'select all files',
            }}
        />
        </TableCell>
        {headCells.map((headCell) => (
        <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: 'bold' }}
        >
            {headCell.label}
            {headCell.sort && (<TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={createSortHandler(headCell.id)}
            >
            {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
            ) : null}
            </TableSortLabel> )}
        </TableCell>
        ))}
    </TableRow>
    </TableHead>
);
}

EnhancedTableHead.propTypes = {
numSelected: PropTypes.number.isRequired,
onRequestSort: PropTypes.func.isRequired,
onSelectAllClick: PropTypes.func.isRequired,
order: PropTypes.oneOf(['asc', 'desc']).isRequired,
orderBy: PropTypes.string.isRequired,
rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;