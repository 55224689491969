import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const InferencePrompt = ({ open, handleClose, handleDeny, handleConfirm }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ marginBottom: 1, fontWeight: "bold" }}>
        Video Generating
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want a video to be generated along with the insights?
          <br></br>
          If you filmed a video while recording data, the predictions will be
          put on top of the video.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeny} style={{ color: "white" }}>
          No, continue with insights only
        </Button>
        <Button onClick={handleConfirm} style={{ color: "green" }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InferencePrompt;
