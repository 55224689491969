import React, { useContext, useState } from "react";
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import { AppContext } from "../AppContext";
import config from "../config";

const DownloadArchive = (props) => {
  const { filePaths, message } = props;
  const { selectedProject, selectedParticipant, selectedPage } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const downloadArchive = async () => {
    setLoading(true);
    const zip = new JSZip();
    let loaded = 0;

    try {
      // Show a loading notification
      Swal.fire({
        title: "Preparing Download...",
        text: "Your download is being prepared. This may take a moment.",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        html: `
          <div style="margin-top: 20px;">
            <p>Download Progress:</p>
            <div id="progress-container" style="width: 100%; background-color: #eee; border-radius: 5px;">
              <div id="progress-bar" style="width: 0%; height: 10px; background-color: #4caf50; border-radius: 5px;"></div>
            </div>
            <p id="progress-text" style="margin-top: 10px;">0%</p>
          </div>
        `,
      });

      const sizeResponse = await fetch(`${config.apiUrl}/getZipSize`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ file_paths: filePaths }),
      });

      const sizeData = await sizeResponse.json();
      const totalSize = sizeData.estimated_size; // Total file size in bytes

      const response = await fetch(`${config.apiUrl}/getPresignedUrls`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          file_paths: filePaths,
          selected_page: selectedPage,
        }),
      });

      const data = await response.json();
      const urls = data.urls;

      for (var i = 0; i < urls.length; i++) {
        let file_url = urls[i];
        let file_id = filePaths[i].split("/").slice(3).join("/");
        try {
          // Stream the file download with axios
          const response = await axios.get(file_url, {
            responseType: "blob",
            onDownloadProgress: (event) => {
              // Calculate file-specific download progress
              const fileLoaded = event.loaded / event.total;
              const percentCompleted = Math.round(
                ((loaded + fileLoaded) * 100) / totalSize
              );
              const progressBar = document.getElementById("progress-bar");
              const progressText = document.getElementById("progress-text");
              if (progressBar && progressText) {
                progressBar.style.width = `${percentCompleted}%`;
                progressText.textContent = `${percentCompleted}%`;
              }
            },
          });

          loaded += response.data.size;

          // Add the file to the zip without loading it fully into memory
          zip.file(file_id, response.data);

          // Update overall progress
        } catch (error) {
          console.error(`Error downloading ${file_id}:`, error);
        }
      }

      var download_filename = "folder.zip";
      if (message == "project") {
        download_filename = `${selectedProject}.zip`;
      } else {
        download_filename = `${selectedProject}_${selectedParticipant}.zip`;
      }

      // Generate the zip file and trigger download
      zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, download_filename);
      });

      // Close the loading notification
      Swal.close();
    } catch (error) {
      console.error("Error downloading the ZIP file:", error);
      Swal.fire({
        title: "Download Error!",
        text: "There was an issue with downloading the file.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Button
        sx={{ color: "white" }}
        onClick={downloadArchive}
        disabled={loading}
      >
        {loading ? "Preparing Download..." : `Download entire ${message}`}
      </Button>
    </React.Fragment>
  );
};

export default DownloadArchive;
